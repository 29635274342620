import './pdf-rates.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as d3 from 'd3';
import Textarea from 'react-textarea-autosize';

import { withTranslation } from 'react-i18next';
import Creatable from 'react-select/creatable';
import {
  changeTeamNote, changePlayerNote, changeFinalRating, changeNoteTags,
} from '../../../../actions/pdf';
import {
  changePlayerNote as matchChangePlayerNote,
  changeTeamNotes as matchChangeTeamNotes,
} from '../../../../actions/match';
import { getWindowWidth, parsePlusTime } from '../../../../helpers';
import { createNoteTag } from '../../../../actions/notes';
import Icon from '../../../Icon';
import { stylePDF, theme } from '../../../ui/select_props';

@withTranslation()
@connect((state) => ({
  pdf: state.pdf,
  teams: state.teams,
  positions: state.positions,
  indicators: state.indicators,
  favorites: state.favorites,
  matches: state.matches,
  notes: state.notes,
  side: state.pdf.side,
  player_id: state.pdf.player_id,
}), {
  changeTeamNote,
  changePlayerNote,
  changeNoteTags,
  changeFinalRating,
  matchChangeTeamNotes,
  matchChangePlayerNote,
  createNoteTag,
})

export default class pdfRates extends Component {
  static propTypes = {
    pdf: PropTypes.object,
    indicators: PropTypes.object,
    favorites: PropTypes.object,
    side: PropTypes.string,
    teams: PropTypes.object,
    positions: PropTypes.object,
    changeFinalRating: PropTypes.func,
    changePlayerNote: PropTypes.func,
    matchChangePlayerNote: PropTypes.func,
    changeTeamNote: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.renderPlayers = this.renderPlayers.bind(this);
    this.renderNotes = this.renderNotes.bind(this);
    this.renderFinalRates = this.renderFinalRates.bind(this);
  }

  renderRates(rates) {
    const {
      t,
      i18n,
      indicators,
    } = this.props;
    const lng = i18n.language;
    const { info } = indicators;
    const gridWidth = getWindowWidth() > 960 ? 32 : 20;
    const color = d3.scaleLinear()
      .domain([-2, -1, 0, 1, 2])
      .range(['#1D619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06']);

    const groups = {
      Physis: null,
      'On the Ball': null,
      'Off the Ball': null,
    };

    _.forIn(rates, (value, key) => {
      const indicator = info[key];
      const cls = indicator.class;
      if (!indicator) return;
      if (!groups[cls]) groups[cls] = {};
      groups[cls][key] = value;
    });

    const tables = [];
    _.forIn(groups, (values, key) => {
      if (!values) return null;

      const localKey = key.replaceAll(' ', '_').toUpperCase();

      tables.push((
        <table key={key}>
          <thead>
            <tr>
              <td>
                <div className="rates-table-title">{t(localKey)}</div>
                <span className="offset">--</span>
              </td>
              <td><span className="offset">-</span></td>
              <td><span className="offset">0</span></td>
              <td><span className="offset">+</span></td>
              <td><span className="offset">++</span></td>
            </tr>
          </thead>
          <tbody>
            {
            _.map(values, (rate_value, rate_name) => {
              const formattedValue = rate_value.toFixed(1) == rate_value ? rate_value : rate_value.toFixed(1);
              const name = _.get(info, `[${rate_name}].name_${lng}`, _.get(info, `[${rate_name}].name`, null));

              return (
                <tr key={rate_name}>
                  <td>
                    <Icon className="rate-indicator-icon" name={rate_name} />
                    {name}
                    {' '}
                    (
                    {formattedValue}
                    )
                  </td>
                  <td className="fixed-width" />
                  <td className="fixed-width" />
                  <td className="fixed-width">
                    <div className="rate-value">
                      <div
                        className="circle"
                        style={{
                          backgroundColor: color(rate_value),
                          left: rate_value * gridWidth,
                        }}
                      />
                    </div>
                  </td>
                  <td className="fixed-width" />
                </tr>
              );
            })
          }
          </tbody>
        </table>
      ));
    });
    return tables;
  }

  renderFinalRates({
    side,
    player_id,
  }) {
    const {
      changeFinalRating,
      pdf,
      t,
    } = this.props;

    const renderName = (rate) => {
      switch (rate) {
        case 0:
          return <span className="name">{t('N/D')}</span>;
        case 1:
          return <span className="name">--</span>;
        case 2:
          return <span className="name">-</span>;
        case 3:
          return <span className="name">0</span>;
        case 4:
          return <span className="name">+</span>;
        case 5:
          return <span className="name">++</span>;
        default:
          return null;
      }
    };

    const currentRating = pdf.match[side].final_rating && pdf.match[side].final_rating[player_id];

    return (
      <div className="final-rating">
        <h4 className="pdf-small-title">{t('FINAL_MATCH_RATING')}</h4>
        <div className="final-rating-list">
          {[1, 2, 3, 4, 5].map((rate, i) => (
            <a
              key={i}
              className={`final-rating-item final-rating-item${rate} ${currentRating == rate ? 'active' : ''}`}
              onClick={() => {
                changeFinalRating({
                  side,
                  player_id,
                  value: rate,
                  match_id: pdf.match.match_id,
                });
              }}
            >
              {renderName(rate)}
            </a>
          ))}
        </div>
      </div>
    );
  }

  renderNotes(notes, side, player_id) {
    const {
      t,
      pdf,
    } = this.props;
    const tagsByKey = _.keyBy(this.props.notes.tags, '_id');
    const tagsOptions = this.props.notes.tags.map((t) => ({
      value: t._id,
      label: t.tag,
    }));

    return _
      .chain(notes)
      .map((note, time) => ({
        text: note,
        time,
      }))
      .groupBy((note) => note.time.toString()
        .slice(0, 1))
      .map((groupByPeriod, period) => (
        <div className="player-note-group" key={period}>
          {
            _.map(groupByPeriod, (note) => {
              const {
                time,
                text,
              } = note;
              const events = pdf.events[side];
              const event = events.find((e) => e.time == time && e.player_id == player_id);
              const tags = pdf.tags[side];
              const tag = tags.find((t) => t.time == (time === 'Note' ? 0 : time) && t.player_id == player_id);
              const tagsValue = (tag && tag.tags) || [];
              const eventEl = event && Array.isArray(event.event_type) ? event.event_type : [];

              const icons = [];

              if (eventEl.indexOf('goal') !== -1) {
                icons.push(<Icon name="goal" className="note-icon goal-icon" key="goal" />);
              }
              if (eventEl.indexOf('own_goal') !== -1) {
                icons.push(<Icon name="owngoal" className="note-icon goal-icon" key="goal" />);
              }
              if (eventEl.indexOf('yellow') !== -1) {
                icons.push(<Icon name="card-pitch" className="note-icon yellow-card-icon" key="yellow" />);
              }
              if (eventEl.indexOf('yellow_red') !== -1) {
                icons.push(<Icon name="yellow_red" className="note-icon yellow-red-card-icon" key="yellow_red" />);
              }
              if (eventEl.indexOf('red') !== -1) {
                icons.push(<Icon name="card-pitch" className="note-icon red-card-icon" key="red" />);
              }
              if (eventEl.indexOf('substitution_in') !== -1) {
                icons.push(<Icon name="substitutionin" className="note-icon substitution_in-icon" key="substitution_in" />);
              }
              if (eventEl.indexOf('substitution_out') !== -1) {
                icons.push(<Icon name="substitutionout" className="note-icon substitution_out-icon" key="substitution_out" />);
              }

              return (
                <div className="player-note-item" key={time}>
                  <div className="note-min">{parseInt(time) ? parsePlusTime(time) : t('NOTE')}</div>
                  <div className="note-event">{icons}</div>
                  <Textarea
                    className="note"
                    placeholder={t('NOTE_PLACEHOLDER', { context: window.genderContext })}
                    value={text}
                    onChange={(e) => {
                      this.props.changePlayerNote({
                        side,
                        player_id,
                        time,
                        note: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      this.props.matchChangePlayerNote({
                        side,
                        player_id,
                        time,
                        note: e.target.value,
                      });
                    }}
                  />
                  <Creatable
                    value={tagsValue.map((t) => ({
                      label: tagsByKey[t].tag,
                      value: tagsByKey[t]._id,
                    }))}
                    className="note-tag-select"
                    placeholder={t('ADD_TAG')}
                    theme={theme}
                    styles={stylePDF}
                    promptTextCreator={(value) => t('CREATE_TAG', value)}
                    onCreateOption={(value) => {
                      this.props.createNoteTag({ tag: value })
                        .then((action) => {
                          const { data } = action.payload;
                          const currentTags = _.get(tag, 'tags', []);
                          const v = [...currentTags, data._id];
                          this.props.changeNoteTags({
                            side,
                            player_id,
                            time,
                            tags: v,
                          });
                          this.props.matchChangePlayerNote({
                            side,
                            player_id,
                            time,
                            tags: v,
                            note: text,
                          });
                        });
                    }}
                    options={tagsOptions}
                    isMulti
                    searchable
                    onChange={(val) => {
                      const v = Array.isArray(val) ? [...val] : [val];

                      this.props.changeNoteTags({
                        side,
                        player_id,
                        time,
                        tags: v.map((t) => t.value),
                      });
                      this.props.matchChangePlayerNote({
                        side,
                        player_id,
                        time,
                        tags: v.map((t) => t.value),
                        note: text,
                      });
                    }}
                  />
                  {eventEl.map((e) => <span className="tag" key={e}>{`#${t(e)}`}</span>)}
                </div>
              );
            })
          }
        </div>
      ))
      .value();
  }

  renderTeamNotes() {
    const {
      teams,
      t,
      i18n,
      side,
      pdf,
    } = this.props;

    const lng = i18n.language;
    const oppositeSide = side === 'home' ? 'away' : 'home';

    return [side, oppositeSide].map((side) => {
      const team_name = _.get(teams, `[${pdf.match[side].team_id}].name_${lng}`, _.get(teams, `[${pdf.match[side].team_id}].name`, t('UNKNOWN_TEAM')));
      return (
        <div className="pdf-team-notes" key={side}>
          <div className="title">{t('TEAM_COMMENTS_FOR', { team_name })}</div>
          <Textarea
            onChange={(e) => {
              this.props.changeTeamNote({
                side,
                note: e.target.value,
              });
            }}
            onBlur={(e) => {
              const obj = {};
              obj[side] = e.target.value;
              this.props.matchChangeTeamNotes(obj);
            }}
            key={pdf.match[side].team_id}
            className="note"
            placeholder={t('TEAM_COMMENTS_PLACEHOLDER', { team_name })}
            value={pdf.team_notes[side]}
          />
        </div>
      );
    });
  }

  renderPlayers(side) {
    const {
      pdf, t,
    } = this.props;

    if (!_.size(pdf.rates[side])) return null;

    const ratesGroups = pdf.rates[side];

    const renderPlayer = () => (
      _.map(pdf.values[side], (values, player_id) => {
        return (
          <div key={player_id}>
            <div className="rated-player">
              <div className="clearfix">
                <div className="rates-group">
                  <h4 className="pdf-small-title">{t('INDICATORS')}</h4>
                  <div className="rates-table">
                    {pdf.rates[side][player_id] && _.size(pdf.rates[side][player_id]) ? this.renderRates(pdf.rates[side][player_id], ratesGroups) : null}
                  </div>
                  {this.renderFinalRates({
                    side,
                    player_id,
                  })}
                </div>
                <div className="rated-notes">
                  <h4 className="pdf-small-title">{t('PLAYER_NOTES')}</h4>
                  {pdf.notes[side][player_id] && _.size(pdf.notes[side][player_id]) ? this.renderNotes(pdf.notes[side][player_id], side, player_id) : null}
                  <div className="team-notes">
                    { this.renderTeamNotes() }
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    );

    return (
      <div>
        {renderPlayer()}
      </div>
    );
  }

  render() {
    const {
      side,
      pdf,
    } = this.props;

    if (!_.size(pdf.match)) return null;

    return (
      <div className="pdf-rates pdf-single-rates">
        {this.renderPlayers(side)}
      </div>
    );
  }
}
